<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="500"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">

                <div class="onlyReadData">
                    {{ $t("Перенести_вложения_в_новую_версию_документа") }}
                </div>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                >
                    {{ $t("Да") }} 
                </v-btn>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="no"
                >
                    {{ $t("Нет") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "CreateNewVersionDlg",
    data () {
        return {
            title: "Версия",

            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            }
        }
    },
    computed: {
        ...mapGetters('dialogs/createNewVersion', { visible: 'isVisible' }),
    },
    methods: {
        ...mapActions('dialogs/createNewVersion', ['ok', 'no', 'cancel'])
    }
}
</script>